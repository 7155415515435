import React, { useEffect, useState, useRef } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import RegisterUserService from '../../services/RegisterUserService';

function EditUser() {
  const { id } = useParams();
  const toast = useRef(null);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    panNumber: "",
    userImage: null,
    passportNumber: "",
    aadharNumber:"",
    isActive: false,
    id: id,
  });
  const [formError, setFormError] = useState({});
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await RegisterUserService.getUserById(id);
        setForm(user);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value, files, type, checked } = e.target;
    if (type === 'checkbox') {
      setForm(prevForm => ({ ...prevForm, [name]: checked }));
    } else if (files) {
      setForm(prevForm => ({ ...prevForm, [name]: files[0] }));
    } else {
      setForm(prevForm => ({ ...prevForm, [name]: value }));
    }
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(form).forEach(key => {
      const value = String(form[key]);
      if (!value.trim() && key !== 'panImage') {
        errors[key] = `Please enter ${key}`;
      }
    });
    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  const updateUser = async () => {
    const isValid = validateForm();
    if (!isValid) return;
    
    const formDataToSend = new FormData();
    
    Object.keys(form).forEach(key => {
      if (key === 'panImage' && form[key] instanceof File) {
        formDataToSend.append(key, form[key]);
      } else {
        formDataToSend.append(key, form[key]);
      }
    });
  
    try {
      await RegisterUserService.updateUser(id, formDataToSend);
      if (toast.current) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'User updated successfully',
          className: 'custom-toast-success',
          life: 3000
        });
      }

      setTimeout(() => {
        // navigate("/registeruser");
        navigate(-1);
      }, 800);
    } catch (error) {
      console.error("Error updating User:", error);
      if (toast.current) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to update user',
          className: 'custom-toast-delete',
          life: 3000
        });

      }
    }
  };
  
  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file); 
      reader.onload = () => {
        const userImage = reader.result.split(',')[1]; 
        setForm({ ...form, userImage });
        setFormError((prevErrors) => ({ ...prevErrors, userImage: '' })); 
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    }
  };
  
  
  return (
    <div>

      <Toast ref={toast} />
      <h2>Edit</h2>

        <form onSubmit={e => e.preventDefault()}>

          <div className="form-group row">
            <label className="col-lg-2" htmlFor="firstName">First Name</label>
            <div className="col-lg-4">
              <input
                disabled
                type="text"
                name="firstName"
                id="firstName"
                className="form-control"
                value={form.firstName}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.firstName}</p>
            </div>
            <label className="col-lg-2" htmlFor="lastName">Last Name</label>
            <div className="col-lg-4">
              <input
                disabled
                type="text"
                name="lastName"
                id="lastName"
                className="form-control"
                value={form.lastName}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.lastName}</p>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-2" htmlFor="email">Email</label>
            <div className="col-lg-4">
              <input
              disabled
                type="email"
                name="email"
                id="email"
                className="form-control"
                value={form.email}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.email}</p>
            </div>
            <label className="col-lg-2" htmlFor="password">Password</label>
            <div className="col-lg-4">
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                value={form.password}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.password}</p>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-2" htmlFor="contactNumber">Contact Number</label>
            <div className="col-lg-4">
              <input
                type="text"
                name="contactNumber"
                id="contactNumber"
                className="form-control"
                value={form.contactNumber}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.contactNumber}</p>
            </div>
            <label className="col-lg-2" htmlFor="streetAddress">Street Address</label>
            <div className="col-lg-4">
              <input
                type="text"
                name="streetAddress"
                id="streetAddress"
                className="form-control"
                value={form.streetAddress}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.streetAddress}</p>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-2" htmlFor="city">City</label>
            <div className="col-lg-4">
              <input
                type="text"
                name="city"
                id="city"
                className="form-control"
                value={form.city}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.city}</p>
            </div>
            <label className="col-lg-2" htmlFor="state">State</label>
            <div className="col-lg-4">
              <input
                type="text"
                name="state"
                id="state"
                className="form-control"
                value={form.state}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.state}</p>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-2" htmlFor="country">Country</label>
            <div className="col-lg-4">
              <input
                type="text"
                name="country"
                id="country"
                className="form-control"
                value={form.country}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.country}</p>
            </div>
            <label className="col-lg-2" htmlFor="postalCode">Postal Code</label>
            <div className="col-lg-4">
              <input
                type="number"
                name="postalCode"
                id="postalCode"
                className="form-control"
                value={form.postalCode}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.postalCode}</p>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-2" htmlFor="panNumber">PAN Number</label>
            <div className="col-lg-4">
              <input
              disabled
                type="text"
                name="panNumber"
                id="panNumber"
                className="form-control"
                value={form.panNumber}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.panNumber}</p>
            </div>
            <label className="col-lg-2" htmlFor="passportNumber">Passport Number</label>
            <div className="col-lg-4">
              <input
              disabled
                type="text"
                name="passportNumber"
                id="passportNumber"
                className="form-control"
                value={form.passportNumber}
                onChange={handleInputChange}
              />
              <p className="text-danger">{formError.passportNumber}</p>
            </div>
          </div>

          <div className="form-group row">
              <label className="col-lg-2" htmlFor="isActive">
                Is Active?
              </label>
              <div className="col-lg-4">
                  <input
                      type="checkbox"
                      name="isActive"
                      id="isActive"
                      checked={form.isActive}
                      onChange={handleInputChange}
                    />
              <p className="text-danger">{formError.isActive}</p>
          </div>
        

            <label className="col-lg-2" htmlFor="userImage">User Image</label>
            <div className="col-lg-4">
              <input
                type="file"
                name="userImage"
                id="userImage"
                className="form-control"
                onChange={onFileChange}
              />
              <p className="text-danger">{formError.userImage}</p>
            </div>
          </div>

          <div className="form-group row">
          <label className="col-lg-2" htmlFor="aadharNumber">Aadhar Number</label>
          <div className="col-lg-4">
          <input
            type="text"
            name="aadharNumber"
            id="aadharNumber"
            className="form-control"
            value={form.aadharNumber}
            onChange={handleInputChange}
          />
          <p className="text-danger">{formError.aadharNumber}</p>
        </div>
        </div>

          <div className="form-group row">
            <div className="col-md-12 text-center">
              <button type="submit" className="btn btn-save btn-dark text-white" onClick={updateUser}>Update</button>
            </div>
          </div>
        </form>
     
    </div>
  );
}

export default EditUser;
