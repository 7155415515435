import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import LeadService from '../../services/LeadService';
import $ from 'jquery';
import 'datatables.net';
import RegisterUserService from '../../services/RegisterUserService';
import AuthService from '../../services/AuthService';
import { FaFileImport, FaRedoAlt, FaUserAlt } from 'react-icons/fa';
import ToastService from '../../services/ToastService';
const API_URL = process.env.REACT_APP_API_URL;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
 
function Leads() {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [employees, setselectedUser] = useState([]);
  const queryParam = useQuery();
  const [selectedValue, setSelectedValue] = useState('');
  const [UserId] = useState(queryParam.get("id") || AuthService.getCurrentUser().UserId);
  const tableRef = useRef(null);
  const [leads, setLeads] = useState([]);
  
  const navigate = useNavigate();
  const [isEmployeeSelectedError, setIsEmployeeSelectedError] = useState(false);

  const toast = useRef(null);
  const toastService = new ToastService(toast); 
  const userRole = AuthService.getLoggedInRoleId();

const currentDate = new Date();
const formattedCurrentDate = currentDate.toISOString().split('T')[0];
const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const formattedStartOfMonth = startOfMonth.toISOString().split('T')[0];

const [startDate, setStartDate] = useState(formattedCurrentDate);
const [endDate, setEndDate] = useState(formattedCurrentDate);

const tenDaysAgo = new Date();
tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
const formattedTenDaysAgo = tenDaysAgo.toISOString().split('T')[0];
const savedPageNumber = localStorage.getItem('currentPageNumber') ? parseInt(localStorage.getItem('currentPageNumber')) : 1;
const [pageNumber, setPageNumber] = useState(savedPageNumber); 
const pageSize = 10;
  async function fetchLeads () {
  try {
    
   // const leadsData = await LeadService.getAllLeads(UserId,pageNumber,pageSize);
    //to sort data in descndng order 
    // const sortedLeads = leadsData.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    // setLeads(sortedLeads);
   //setLeads(leadsData);
  } catch (error) {
    console.error('Error fetching leads:', error);
    toastService.showError("Error", "Failed to fetch leads data");
  }
};

async function fetchEmployee () {
  try { 
    const users = await RegisterUserService.getEmployessByRoleId(4);
    setselectedUser(users);
  } catch (error) {
    console.error('Error fetching employees:', error);
    toastService.showError("Error", "Failed to fetch employees data");
  }
};

useEffect(() => {
 // fetchLeads(startDate, endDate);

  fetchEmployee();
  setTimeout(() => {
    $('#tableData').DataTable();
  }, 1000);
}, [UserId]);

// useEffect(() => {
//   fetchEmployee();
// }, [UserId]);

const handleCheckboxChange = (leadId) => {
  const updatedSelectedRows = new Set(selectedRows);
  if (updatedSelectedRows.has(leadId)) {
    updatedSelectedRows.delete(leadId);
  } else {
    updatedSelectedRows.add(leadId);
  }

  setSelectedRows(updatedSelectedRows);
};

useEffect(() => {
    const token = AuthService.getToken(); 
   const table = $(tableRef.current).DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${API_URL}/Lead/GetAllLeads/${UserId}/${pageNumber}/${pageSize}`,
        type: 'POST',
        datatype: 'json',
        headers: {
          "Authorization": `Bearer ${token}`, // Add the token here
        },
        dataSrc: function (json) {
          console.log("API Response:", json);
          return json.value.data.$values || [];
        // return json && json.value && json.value.data && json.value.data.$values ? json.value.data.$values : [];
        },
        error: function (xhr, error, code) {
          console.error('API Request failed:', error, xhr);
          toastService.showError("Error", "Failed to fetch leads data");
        },
      },
      columnDefs: [
        { targets: [0], visible: true, searchable: false },
      ],
      columns: [
        {
          data: "id",
          name: "Select",
          render: function (data, type, row) {
            console.log('Selected rows:', selectedRows);
            const isChecked = selectedRows.has(row.id);
            const isDisabled = row.isLeadAssigned;
            return `<input type="checkbox" class="row-checkbox" data-row="${row.id}" ${isDisabled ? 'disabled' : ''} ${isChecked ? 'checked' : ''} /> `;
          },
          orderable: false, 
          searchable: false, 
        },
        { data: 'leadCode', name: 'Lead Code', autoWidth: true },
        { data: 'firstName', name: 'First Name', autoWidth: true },
        { data: 'email', name: 'Email', autoWidth: true },
        { data: 'contactNumber', name: 'Mobile', autoWidth: true },
        { data: 'address', name: 'Address', autoWidth: true },
        { data: 'property', name: 'Property', autoWidth: true },
        { data: 'source', name: 'Source', autoWidth: true },
        {
          data: 'startDate',
          name: 'Start Date',
          render: function (data) {
            return new Date(data).toLocaleDateString(); 
          },
          autoWidth: true,
        },
        {
          data: 'endDate',
          name: 'End Date',
          render: function (data) {
            return new Date(data).toLocaleDateString(); 
          },
          autoWidth: true,
        },
        {
          data: null,
          render: function (data, type, row) {
            return `
              <button class="btn btn-outline-dark btn-sm edit-lead-btn" data-id="${row.id}">
                <i class="pi pi-pencil" style="font-size: 1.5rem;"></i>
              </button>
            `;
          },
          orderable: false, 
          searchable: false, 
        },
      ],
      drawCallback: function (settings) {
        const currentPage = Math.floor(settings._iDisplayStart / settings._iDisplayLength) + 1;
        if (currentPage !== pageNumber) {
          setPageNumber(currentPage);  // Update pageNumber
          localStorage.setItem('currentPageNumber', currentPage);
          $(tableRef.current).find('.row-checkbox').each(function () {
            const rowId = $(this).data('row');
            if (selectedRows.has(rowId)) {
              $(this).prop('checked', true);
            } else {
              $(this).prop('checked', false);
            }
          });
      } 
      if (selectedRows.size > 0) {
        table.draw();
      }
      },
      stateSave: true,
    });

    
    $(tableRef.current).on('click', '.edit-lead-btn', function () {
      const leadId = $(this).data('id');
      navigate(`/editlead/${leadId}`);
    });
    $(tableRef.current).on('change', '.row-checkbox', function () {
      const leadId = $(this).data('row');
      handleCheckboxChange(leadId);
    });

  return () => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }
  };
}, [UserId, pageNumber, pageSize]);



  const handleDateChange = (event) => {
    const value = event.target.value;
    const today = formattedCurrentDate;

    if (value === 'today') {
      setStartDate(today);
      setEndDate(today);
    } else if (value === 'yesterday') {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const formattedYesterday = yesterday.toISOString().split('T')[0];
      setStartDate(formattedYesterday);
      setEndDate(formattedYesterday);
    } else if (value === 'last10days') {
      setStartDate(formattedTenDaysAgo);
      setEndDate(today);
    } else if (value === 'thisMonth') {
      setStartDate(formattedStartOfMonth);
      setEndDate(today);
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event);
    if (event) {
      setIsEmployeeSelectedError(false);
    }
  };

  const sourceTypes = [
    { id: 1, name: "Facebook" },
    { id: 2, name: "Instagram" },
    { id: 3, name: "Reference" },
  ];

  const AssignLead = async () => {
    setIsEmployeeSelectedError(!selectedValue);
    if (selectedRows.size === 0) {
      toastService.showError("Error", "Please select at least one lead");
      return;
    }
  
    if (!selectedValue) {
      toastService.showError("Validation Error", "Please select an employee.");
      return;
    }
  
    try {
      await LeadService.AssignedLeadsToEmployee(selectedValue, Array.from(selectedRows));
      toastService.showSuccess("Success", "Leads assigned successfully");
      setSelectedValue('');
      setSelectedRows(new Set());
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        const table = $(tableRef.current).DataTable();
        table.ajax.reload(null, false);  // This will reload the data and preserve the current page (false means do not reset to page 1)
      }
    }
    catch (error) {
      toastService.showError("Error", "Failed to assign leads");
    }
  };
  
  const Role = {
    SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
    ADMIN: { roleId: 2, roleName: 'Admin' },
    MANAGER: { roleId: 3, roleName: 'Manager' },
    EMPLOYEE: { roleId: 4, roleName: 'Employee' },
  };

  const renderLeads = () => {
    return leads.map((item) => {
   
      return (
        <tr key={item.id}>
          <td> 
            <input
              type="checkbox"
              className="row-checkbox"
              data-row={item.id}
              disabled={item.isLeadAssigned}
              checked={selectedRows.has(item.id)}
              onChange={() => handleCheckboxChange(item.id)}
            /> 
          </td>
          <td>{item.leadCode}</td>
          <td>{item.firstName}</td>
          <td>{item.email}</td>
          <td>{item.contactNumber}</td>
          <td>{item.address}</td>
          <td>{item.property}</td>
          <td>{item.source}</td>
          <td>
            {new Date(item.startDate).toLocaleDateString()}
          </td>

          <td>
            {new Date(item.endDate).toLocaleDateString()}
          </td>

          {/* <td className={getStatusClass(sourceTypeName)}>
            {sourceTypeName}
          </td> */}

          <td>
            <button
              className="btn btn-outline-dark btn-sm"
              onClick={() => navigate(`/editlead/${item.id}`)}
            >
              <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
            </button>

          </td>
        </tr>
      );
    });
  };

  function getStatusClass(sourceTypeName) {
    switch (sourceTypeName) {
      case 'Facebook':
        return 'table-warning'; 
      case 'Instagram':
        return 'table-danger'; 
      case 'Reference':
        return 'table-info';   
      default:
        return ''; 
    }
  }

  return (
    <div>
      <Toast ref={toast} />

      <h1>Leads</h1>

      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (

      <div className='form-group row'>

        <div className='col-lg-6'>
          <div className="form-group row">           
            <div className="col-lg-6">
              <select
                name="userId"
                id="userId"
                className={`form-control ${isEmployeeSelectedError ? 'error' : (selectedValue ? 'valid' : '')}`}
                value={selectedValue}
                onChange={(e) => handleChange(e.target.value)}
              >
                <option value="" disabled>Select an employee to assign lead(s)</option>
                {employees.map(employee => (
                  <option key={employee.id} value={employee.id}>
                    {employee.firstName}
                  </option>
                ))}
              </select>
              {isEmployeeSelectedError && (
                <div className="text-danger mt-2">
                  Please select an employee
                </div>
              )}
            </div>

            <div className="col-lg-3">
              <button className="btn btn-save text-white" onClick={() => AssignLead()}>
              <FaUserAlt/>  Assign Leads
              </button>
            </div>
          </div>
        </div>
        
        <div className='col-lg-6 form-group row'>
          <div className="col-lg-4">
            <button
              className="btn btn-save text-white"
              onClick={() => navigate("/importLeads")}
            >
             <FaFileImport/> Import Leads
            </button>
          </div>
        </div>
      </div>
      )}

      <div className='form-group row'>
        <div>
          <button
            className="btn btn-save offset-11 text-white"
            onClick={() => navigate("/addlead")}
          >
            <i className="pi pi-plus"></i>
            Add
          </button>
        </div>
      </div> 

      <div className="p-2 m-2">
        <table id='tableData' ref={tableRef} className="table display" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Select</th>
              <th>Lead Code</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Address</th>
              <th>Property</th>
              <th>Source</th>
              <th>Start Date</th>
              <th>End Date</th>
              {/* <th>Source Type</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Leads;
