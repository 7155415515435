import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css'; 
import ToastService from '../../Admin/services/ToastService';
import AuthService from '../../Admin/services/AuthService';
import ClientService from '../services/ClientService';
import { counter } from '@fortawesome/fontawesome-svg-core';
import { MdAddCircleOutline } from 'react-icons/md';



function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AddClient() {

    const queryParam = useQuery();
    const navigate = useNavigate();
    const toast = useRef(null);
    const [Clients, setClients] = useState([]);
    const toastService = new ToastService(toast); 
    const [ClientId] = useState(null);
  
    const [form, setForm] = useState({ 
      agencyName: "",
      email: "",
      phoneNumber: "",
      streetaddress: "",  
      city: "",
      state: "",
      country:"",
      postalcode:"",
      gstNumber:"",
      agencyLogo:null,
      license:"",
      registrationNumber:"",
      isActive:true,
    });
  
    const [formError, setFormError] = useState([]);
  
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
        setFormError((prevErrors) => ({ ...prevErrors, [name]: '' })); 
      };

      const onFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file); 
          reader.onload = () => {
            const agencyLogo = reader.result.split(',')[1]; 
            setForm({ ...form, agencyLogo });
            setFormError((prevErrors) => ({ ...prevErrors, agencyLogo: '' })); 
          };
          reader.onerror = (error) => {
            console.error('Error reading file:', error);
          };
        }
      };

      async function getAll() {
        try {
          const clientsData = await ClientService.getAllCLients();
          setClients(clientsData);
        } catch (error) {
          console.log('Error fetching Clients: ', error);
        }
      }
    
      useEffect(() => {
        getAll();
      }, [ClientId]);


      function onClientSubmit() {

        const errors = {
          agencyName: form.agencyName.trim().length === 0,
        };
      
        const errorMessages = Object.keys(errors).reduce((acc, key) => {
          if (errors[key] === true) {
            return {
              ...acc,
              [key]: `Please enter ${key}!!!`,
            };
          } else if (typeof errors[key] === 'string') {
            return {
              ...acc,
              [key]: errors[key],
            };
          }
          return acc;
        }, {});
      
    
        setFormError(errorMessages);
      
        if (Object.keys(errorMessages).length === 0) {
         saveClient();
        }
      }
      
    function saveClient() {   
          const requestData = {
            agencyName: form.agencyName,
            email: form.email,
            phoneNumber: form.phoneNumber,
            streetaddress: form.streetaddress,
            city: form.city,
            state: form.state,
            country: form.country,
            postalcode: form.postalcode,
            gstNumber: form.gstNumber,
            agencyLogo: form.agencyLogo,
            license: form.license,
            registrationNumber: form.registrationNumber,
            isActive:true,
          };
          
          try {
         ClientService.createClient(requestData);        
            resetForm();
            navigate("/client")
          } catch (error) {
            if (error.response && error.response.status === 400) {
              const validationErrors = error.response.data.errors;
              console.log("Validation errors:", validationErrors);
              toastService.showError("Validation Error", "Please fill out all required fields");
            } else {
              console.log("Error saving client:", error);
              toastService.showError("Error", "Error saving client");
            }
          }
        }
    
      function resetForm() {
        setForm({
          agencyName: "",
          email: "",
          phoneNumber: "",
          streetaddress: "", 
          city: "",
          state: "",
          country:"",
          postalcode:"",
          gstNumber:"",
          agencyLogo:"",
          license:"",
          registrationNumber:""
        });
        setFormError([]);
      }


  return (
    <div>
         <Toast ref={toast} />

         
      <div className="row m-2 p-2">
            <div className="card text-center mx-auto">
                <div className="card-header bg-heading text-white">
                <MdAddCircleOutline />  New Client
                </div>

                <div className="card-body text-start">

                    <div className="form-group row">
                        <label className="col-lg-2 text-dark" htmlFor="agencyName">
                             Name
                        </label>
                        <div className="col-lg-4">
                            <input 
                              type="text" 
                              name="agencyName" 
                              id="agencyName" 
                              className={`form-control ${formError.agencyName ? 'is-invalid' : ''}`}
                              placeholder="Agency Name" 
                              onChange={changeHandler} 
                              value={form.agencyName} 
                              />
                            {formError.agencyName && <div className="invalid-feedback">{formError.agencyName}</div>}   
                            <p></p> 
                        </div>
                        <label className="col-lg-2 text-dark" htmlFor="email">
                        Email
                        </label>
                        <div className="col-lg-4">
                            <input 
                              type="text" 
                              name="email" 
                              id="email" 
                              className={`form-control ${formError.email ? 'is-invalid' : ''}`}
                              placeholder="Email" 
                              onChange={changeHandler} 
                              value={form.email} 
                              />
                        </div>
                    </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="phoneNumber">
                            Phone Number
                        </label>
                        <div className="col-lg-4">
                            <input type="text" name="phoneNumber" id="phoneNumber" 
                            className={`form-control ${formError.phoneNumber ? 'is-invalid' : ''}`}
                            placeholder="Phone Number" onChange={changeHandler}
                            value={form.phoneNumber} 
                            />
                          <p></p> 
                        </div>

                    <label className="col-lg-2 text-dark" htmlFor="streetaddress">
                           Street Address
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="text" 
                            name="streetaddress" 
                            id="streetaddress"
                            className={`form-control ${formError.streetaddress ? 'is-invalid' : ''}`}
                            placeholder="Street Address" 
                            onChange={changeHandler}
                            value={form.streetaddress}                          
                            />
                           <p></p>
                        </div>
                    </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="city">
                            City
                        </label>
                        <div className="col-lg-4">
                            <input type="text" name="city" id="city" 
                            className={`form-control ${formError.city ? 'is-invalid' : ''}`}
                            placeholder="City" onChange={changeHandler}
                            value={form.city} 
                            />
                          <p></p> 
                        </div>

                    <label className="col-lg-2 text-dark" htmlFor="state">
                           State
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="text" 
                            name="state" 
                            id="state"
                            className={`form-control ${formError.state ? 'is-invalid' : ''}`}
                            placeholder="State" 
                            onChange={changeHandler}
                          value={form.state} 
                            />
                           <p></p>
                        </div>
                    </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="country">
                            Country
                        </label>
                        <div className="col-lg-4">
                            <input type="text" name="country" id="country" 
                            className={`form-control ${formError.country ? 'is-invalid' : ''}`}
                            placeholder="Country" onChange={changeHandler}
                            value={form.country} 
                            />
                          <p></p> 
                        </div>

                    <label className="col-lg-2 text-dark" htmlFor="postalcode">
                    Postalcode
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="number" 
                            name="postalcode" 
                            id="postalcode"
                            className={`form-control ${formError.postalcode ? 'is-invalid' : ''}`}
                            placeholder="Postalcode" 
                            onChange={changeHandler}
                          value={form.postalcode} 
                            />
                           <p></p>
                        </div>
                    </div>
                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="gstNumber">
                    Gst Number
                        </label>
                        <div className="col-lg-4">
                            <input type="text" name="gstNumber" id="gstNumber" 
                            className={`form-control ${formError.gstNumber ? 'is-invalid' : ''}`}
                            placeholder="Gst Number" onChange={changeHandler}
                            value={form.gstNumber} 
                            />
                          <p></p> 
                        </div>

                    <label className="col-lg-2 text-dark" htmlFor="agencyLogo">
                    Agency Logo
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="file" 
                            name="agencyLogo" 
                            id="agencyLogo"
                            className={`form-control ${formError.agencyLogo ? 'is-invalid' : ''}`}
                            placeholder="Agency Logo" 
                            onChange={onFileChange}
                            />
                           <p></p>
                        </div>
                    </div>
                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="license">
                     License
                        </label>
                        <div className="col-lg-4">
                            <input type="text" name="license" id="license" 
                            className={`form-control ${formError.license ? 'is-invalid' : ''}`}
                            placeholder="License" onChange={changeHandler}
                            value={form.license} 
                            />
                          <p></p> 
                        </div>

                    <label className="col-lg-2 text-dark" htmlFor="registrationNumber">
                    Registration Number
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="text" 
                            name="registrationNumber" 
                            id="registrationNumber"
                            className={`form-control ${formError.registrationNumber ? 'is-invalid' : ''}`}
                            placeholder="Registration Number" 
                            onChange={changeHandler}
                          value={form.registrationNumber} 
                            />
                           <p></p>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-muted bg-light">
                    <button className="btn btn-save btn-dark text-white" type="submit" onClick={onClientSubmit}>
                    <i className="fas fa-save"></i> Submit
                    </button>
                </div>
            </div>
        </div>

    </div>
  )
}

export default AddClient