import React, { useEffect, useState, useRef } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import LeadsAssignedService from '../../services/LeadsAssignedService';
import $ from 'jquery';
import 'datatables.net';
import AuthService from '../../services/AuthService';
import RegisterUserService from '../../services/RegisterUserService';
import { MdRepeat } from 'react-icons/md';
import ToastService from '../../services/ToastService';
import LeadService from '../../services/LeadService';
const API_URL = process.env.REACT_APP_API_URL;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LeadsAssigned() {
 const [selectedRows, setSelectedRows] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false); 
  const [LeadsAssigned, setLeadsAssigned] = useState([]);
  const navigate = useNavigate(); 
  const toast = useRef(null);
  const [searchTerm] = useState('');
  const tableRef = useRef(null);
  const [employees, setselectedUser] =  useState([]);
  const queryParam = useQuery();
  const [selectedValue, setSelectedValue] = useState('');
  const [UserId] = useState(queryParam.get("id") || AuthService.getCurrentUser().UserId);
  const userRole = AuthService.getLoggedInRoleId(); 
  const [isEmployeeSelectedError, setIsEmployeeSelectedError] = useState(false);
  const toastService = new ToastService(toast);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [leadStatus, setLeadStatus]= useState([]);
  const savedPageNumber = localStorage.getItem('currentPageNumber') ? parseInt(localStorage.getItem('currentPageNumber')) : 1;
  const [pageNumber, setPageNumber] = useState(savedPageNumber); 
   const pageSize = 10;

  useEffect(() => {    
    const fetchLeadsAssigned = async () => {
      try {
        const userId = UserId;
        const leadsassignedData = await LeadsAssignedService.getAllLeadsAssigned(userId);

        const sortedLeadsAssigned = leadsassignedData.sort((a, b) => new Date(b.leadAssignedDate) - new Date(a.leadAssignedDate));
        setLeadsAssigned(sortedLeadsAssigned);

        setLeadsAssigned(leadsassignedData);
      } catch (error) {
        console.error('Error fetching leads assigned:', error);  
        toastService.showError("Error", "Failed to fetch leads assigned data");
      }
    };
    const fetchEmployee = async()=> {
      try {
        const users = await RegisterUserService.getEmployessByRoleId(4);
        setselectedUser(users);
      } catch (error) { 
        console.error('Error fetching users:', error);  
        toastService.showError("Error", "Failed to fetch users data");
      }
    };

    const fetchLeadStatus =async()=>{
        try{
          const leadstatus = await LeadService.getAllLeadStatus();
          setLeadStatus(leadstatus);

        }catch(error){
          console.error('Error fetching lead status:', error);
        }
    }

    fetchLeadStatus();
   // fetchLeadsAssigned();
    fetchEmployee();

    // const table = $(tableRef.current).DataTable();

    // return () => {
    //   table.destroy();
    // };

    setTimeout(() => {
      $('#tableData').DataTable();
    }, 1000);

  }, [UserId]);

  const filteredLeadsAssigned = LeadsAssigned.filter((leadassigned) => {
    const statusMatches = selectedStatus ? leadassigned.leadStatusId === parseInt(selectedStatus) : true;
    const searchMatches = leadassigned.conversationComment &&
      leadassigned.conversationComment.toLowerCase().includes(searchTerm.toLowerCase());
    return statusMatches && searchMatches;
  });


  
  useEffect(() => {
    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }
  }, []);

//   const setReminder = (nextUpdate, followUpTime, leadName, leadCode, leadContactNumber) => {
//     const [hours, minutes] = followUpTime.split(':');
//     const followUpDateTime = new Date(`${nextUpdate}`);

//     const currentTime = new Date();
//     const timeDifference = followUpDateTime - currentTime;

//     if (timeDifference > 0) {
//         setTimeout(() => {
//             if (Notification.permission === 'granted') {
//                 new Notification(`Follow up Reminder`, {
//                     body: `It's time to follow up with ${leadName}, ${leadCode}, ${leadContactNumber}!`,
//                 });
//             } 
//             // else {
//             //     alert(`It's time to follow up with ${leadName}!`);
//             // }
//         }, timeDifference);
//     }
// };


  const printLead = (leadassigned) => {
    const printWindow = window.open('', '_blank');
    const lead = leadassigned.lead;
    const sourceType = lead ? sourceTypes.find((source) => source.id === lead.sourceTypeId) : null;
    const sourceTypeName = sourceType ? sourceType.name : "Unknown";
    const leadStatus = LeadStatus.find((leadstatus) => leadstatus.id === leadassigned.leadStatusId);
    const leadStatusName = leadStatus ? leadStatus.name : "Unknown";
    const property = PropertyType.find((propertytype) => propertytype.id === leadassigned.propertyTypeId);
    const propertyTypeName = property ? property.name : "Unknown";
 
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Lead</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ccc; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          <h2>Lead Details</h2>
          <table> 
            <tr><th>Lead Name</th><td>${lead ? `${lead.firstName}` : 'Unknown Lead'}</td></tr>
            <tr><th>Email</th><td>${lead ? lead.email : 'Unknown Lead'}</td></tr>
            <tr><th>Contact Number</th><td>${lead ? lead.contactNumber : 'Unknown Lead'}</td></tr>
            <tr><th>Lead Start Date</th><td>${lead ? new Date(lead.startDate).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
              }) : 'Unknown Lead'}</td></tr>
            <tr><th>Lead Assigned Date</th><td>${new Date(leadassigned.leadAssignedDate).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
              })}</td></tr>
            <tr><th>Source Type</th><td>${sourceTypeName}</td></tr>
            <tr><th>Lead Status</th><td>${leadStatusName}</td></tr>
            <tr><th>Property Type</th><td>${propertyTypeName}</td></tr>
            <tr><th>Conversation Comments</th><td>${leadassigned.conversationComment}</td></tr>
          </table>
          <br>
          <button onclick="window.print()">Print</button>
        </body>
      </html>
    `);
    printWindow.document.close();
  };


  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedRows(LeadsAssigned.map(lead => lead.leadId)); 
    } else {
      setSelectedRows([]); 
    }
  };

  const handleRowSelection = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(id => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };
  

  const handleChange = (event) => {
    setSelectedValue(event);
    if (event) {
      setIsEmployeeSelectedError(false); 
    }
  };
  

  const sourceTypes = [
    { id: 1, name: "Facebook" },
    { id: 2, name: "Instagram" },
    { id: 3, name: "Reference" },
  ];

  $('#tableData').on('change', '.row-checkbox', function () {
    const rowIndex = $(this).data('row');
    const isChecked = $(this).is(':checked');
    const updatedSelection = isChecked
      ? [...selectedRows, rowIndex]
      : selectedRows.filter(index => index !== rowIndex);
    setSelectedRows(updatedSelection);
  });

  const ReAssignLead = async()=>{
    if (!selectedValue) {
      toastService.showWarning("Warning", "Please select an employee to re-assign the lead");
      return;
    }
    if (selectedRows.length === 0) {
      toastService.showWarning("Warning", "Please select at least one lead to re-assign");
      return;
    }
    
    try {
      await LeadsAssignedService.ReAssignedLeads(selectedValue, selectedRows);
        toastService.showSuccess("Success", "Lead Re-Assigned successfully");
        setSelectedValue('');
        setSelectedRows(new Set());
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
          const table = $(tableRef.current).DataTable();
          table.ajax.reload(null, false); 
        }
    } catch (error) {
      toastService.showError("Error", "Failed to Assign lead");
    }
  }

  function getStatusClass(leadStatusName) {
    switch (leadStatusName) {
      case 'Interested':
        return 'table-success'; 
      case 'HotClient':
        return 'table-danger'; 
      case 'NotInterested':
        return 'table-info';   
      case 'CallBackLater':
        return 'table-warning'; 
      case 'InvalidNumber':
        return 'table-primary';
      case 'Complete':
        return 'table-primary';
        case 'Visited':
          return 'table-info';
          case 'UpcomingVisit':
            return 'table-warning';
      default:
        return ''; 
    }
  }
  

  const Role = {
    SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
    ADMIN: { roleId: 2, roleName: 'Admin' },
    MANAGER: { roleId: 3, roleName: 'Manager' },
    EMPLOYEE: { roleId: 4, roleName: 'Employee' },
  };


  const LeadStatus= [
    { id: 1, name: "HotClient" },
    { id: 2, name: "Interested" },
    { id: 3, name: "NotInterested" },
    { id: 4, name: "PhoneNotPicked" },
    { id: 5, name: "InvalidNumber" },
    { id: 6, name: "ContactAfterSomeTime" },
    { id: 7, name: "CallBackLater" },
    { id: 8, name: "Upcoming Visit" },
    { id: 9, name: "Complete" },
    { id: 10, name: "Visited" },
  ];

  const PropertyType= [ 
    { id: 1, name: "1BHK" },
    { id: 2, name: "2BHK" },
    { id: 3, name: "3BHK" },
    { id: 4, name: "4BHK" },
    { id: 5, name: "5BHK" },
    { id: 6, name: "Plot" },
    { id: 7, name: "Kothi" },
    { id: 8, name: "Other" },
  ];

  const renderLeadsAssigned = () => {
    return filteredLeadsAssigned.map((leadassigned) => {
    
      const lead = leadassigned.lead;

      const sourceType = lead ? sourceTypes.find((source) => source.id === lead.sourceTypeId) : null;
      const sourceTypeName = sourceType ? sourceType.name : "Unknown";
  
      const leadStatus = LeadStatus.find((leadstatus) => leadstatus.id === leadassigned.leadStatusId);
      const leadStatusName = leadStatus ? leadStatus.name : "Unknown";

      const property = PropertyType.find((propertytype) => propertytype.id === leadassigned.propertyTypeId);
      const propertyTypeName = property ? property.name : "Unknown";

      const employee = employees.find((emp) => emp.id === leadassigned.userId);
      const employeeName = employee ? `${employee.firstName} ${employee.lastName}` : 'InActive Employee';

      
     // setReminder(leadassigned.nextUpdate, leadassigned.followUpTime, lead ? lead.firstName : 'Unknown Lead', lead ? lead.leadCode : 'Unknown Lead', lead ? lead.contactNumber : 'Unknown Lead');

      return (
        <tr key={leadassigned.id}>
          {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
          <td>
          <input 
            type="checkbox" 
            checked={selectedRows.includes(leadassigned.leadId)}
            onChange={() => handleRowSelection(leadassigned.leadId)} />
          </td>
          )}
          <td>{leadassigned.conversationComment}</td>
          <td>{lead ? `${lead.leadCode}` : 'Unknown Lead'}</td>
          <td>{lead ? `${lead.firstName}` : 'Unknown Lead'}</td>
          {/* <td>{lead ? `${lead.email}` : 'Unknown Lead'}</td> */}
          <td>{lead ? `${lead.contactNumber}` : 'Unknown Lead'}</td>
          <td>{lead ? `${lead.source}` : 'Unknown Lead'}</td>
          {/* <td>{sourceTypeName}</td> */}
          <td className={getStatusClass(leadStatusName)}> 
                {leadStatusName}
          </td>
          <td>{propertyTypeName}</td> 
          <td>{lead ? `${lead.property}` : 'Unknown Lead'}</td>
          <td>
            {new Date(leadassigned.leadAssignedDate).toLocaleDateString()}
          </td>
          <td>{employeeName}</td>
          <td>
              {new Date(leadassigned.followUpDate).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
              })}
          </td>

          <td>
              {new Date(leadassigned.nextUpdate).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
              })}
          </td>
          <td>
            <button
              className="btn btn-outline-dark btn-sm"
              onClick={() => navigate(`/editleadassigned/${leadassigned.id}`)}
            >
              <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
            </button>
            {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
            <button className="btn btn-outline-dark btn-sm" onClick={() => printLead(leadassigned)}>
            <i class="fas fa-print" style={{ fontSize: '1.5rem' }}></i>
            </button>
            )}
          </td>
        </tr>
      );
    });
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value); 
  };

  const handleCheckboxChange = (leadId) => {
    const updatedSelectedRows = new Set(selectedRows);  
    if (updatedSelectedRows.has(leadId)) {
      updatedSelectedRows.delete(leadId);  
    } else {
      updatedSelectedRows.add(leadId);  
    }
    setSelectedRows(updatedSelectedRows);
  };
  
//   useEffect(() => {
//     const token = AuthService.getToken(); 
//     const table = $(tableRef.current).DataTable({
//       processing: true,
//       serverSide: true,
//       ajax: {
//         url: `${API_URL}/LeadAssigned/GetAllLeadAssignedByUserId/${UserId}/${pageNumber}/${pageSize}`,
//         type: 'POST',
//         datatype: 'json',
//         headers: {
//           "Authorization": `Bearer ${token}`, // Add the token here
//         },
//         dataSrc: function (json) {
//           console.log("API Response:", json);
//           return json.value.data.$values || [];
//         },
//         error: function (xhr, error, code) {
//           console.error('API Request failed:', error, xhr);
//           toastService.showError("Error", "Failed to fetch leads data");
//         },
//       },
//       columnDefs: [
//         { targets: [0], visible: true, searchable: false },
//       ],
//       columns: [
//         {
//           data: "leadId",
//           name: "Select",
//           render: function (data, type, row) {
//             debugger
//            const isChecked = selectedRows.has(row.leadId);
//             const isDisabled = row.isLeadAssigned;
//             return `<input type="checkbox" class="row-checkbox" data-row="${row.leadId}" ${isDisabled ? 'disabled' : ''} ${isChecked ? 'checked' : ''} /> `;
//           },
//           orderable: false, 
//           searchable: false, 
//         },
//         { data: 'conversationComment', name: 'Conversation Comment', autoWidth: true },
//         {
//           data: null, 
//           name: 'Lead Code', 
//           render: function (data, type, row) {
//             return `<span class="text-secondary text-xs font-weight-bold">${row.lead ? row.lead.leadCode : 'Unknown Lead'}</span>`;
//           },
//           autoWidth: true,
//         },
//         {
//           data: null, 
//           name: 'First Name',
//           render: function (data, type, row) {
//             return `<span class="text-secondary text-xs font-weight-bold">${row.lead ? row.lead.firstName : 'Unknown Lead'}</span>`;
//           },
//           autoWidth: true,
//         },
//         {
//           data: null, 
//           name: 'Contact Number',
//           render: function (data, type, row) {
//             return `<span class="text-secondary text-xs font-weight-bold">${row.lead ? row.lead.contactNumber : 'Unknown Lead'}</span>`;
//           },
//           autoWidth: true,
//         },
//        {
//         data: null, 
//         name: 'Source',
//         render: function (data, type, row) {
//           return `<span class="text-secondary text-xs font-weight-bold">${row.lead ? row.lead.source : 'Unknown Lead'}</span>`;
//         },
//         autoWidth: true,
//       },
//         {
//           data: null, 
//           name: 'Lead Status',
//           render: function (data, type, row) {
//             return `<span class="text-secondary text-xs font-weight-bold">${row.lead ? row.leadStatus.leadStatusName : 'Unknown Lead'}</span>`;
//           },
//           autoWidth: true,
//         },
//         {
//           data: null, 
//           name: 'Property',
//           render: function (data, type, row) {
//             return `<span class="text-secondary text-xs font-weight-bold">${row.lead ? row.lead.property : 'Unknown Lead'}</span>`;
//           },
//           autoWidth: true,
//         },
//         {
//           data: 'leadAssignedDate',
//           name: 'LeadAssigned Date',
//           render: function (data) {
//             debugger
//             return new Date(data).toLocaleDateString(); 
//           },
//           autoWidth: true,
//         },
//         {
//           data: null, 
//           name: 'Employee Name',
//           render: function (data, type, row) {
//             return `<span class="text-secondary text-xs font-weight-bold">${row.user ? row.user.firstName + ' ' + row.user.lastName  : 'Unknown Lead'}</span>`;
//           },
//           autoWidth: true,
//         },
//         {
//           data: 'followUpDate',
//           name: 'followUpDate',
//           render: function (data) {
//             return new Date(data).toLocaleString('en-US', {
//               hour: 'numeric', minute: 'numeric', second: 'numeric',
//               hour12: true, year: 'numeric', month: 'long', day: 'numeric'
//             });
//           },
//           autoWidth: true,
//         },
//         {
//           data: 'nextUpdate',
//           name: 'Next Update',
//           render: function (data) {
//             return new Date(data).toLocaleString('en-US', {
//               hour: 'numeric', minute: 'numeric', second: 'numeric',
//               hour12: true, year: 'numeric', month: 'long', day: 'numeric'
//             });
//           },
//           autoWidth: true,
//         },
//         {
//           data: null,
//           render: function (data, type, row) {
//             const isAuthorized = userRole == Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId;
//             let actions = `
//               <button class="btn btn-outline-dark btn-sm edit-lead-btn" data-id="${row.id}">
//                 <i class="pi pi-pencil" style="font-size: 1.5rem;"></i>
//               </button>
//             `;
//             if (isAuthorized) {
//               actions += `
//                 <button class="btn btn-outline-dark btn-sm print-lead-btn" data-id="${row.id}">
//                   <i class="fas fa-print" style="font-size: 1.5rem;"></i>
//                 </button>
//               `;
//             }
//             return actions;
//           },
//           orderable: false,  
//           searchable: false, 
//         }
//       ],
//       drawCallback: function (settings) {
//         const currentPage = Math.floor(settings._iDisplayStart / settings._iDisplayLength) + 1;
//         if (currentPage !== pageNumber) {
//           setPageNumber(currentPage);  
//           localStorage.setItem('currentPageNumber', currentPage);
//           $(tableRef.current).find('.row-checkbox').each(function () {
//             const leadId = $(this).data('row'); 
//             const rowData = settings.aoData.find(row => row._aData.leadId === leadId);
//             if (rowData) {
//               const isDisabled = rowData._aData.isLeadAssigned;
//               $(this).prop('disabled', isDisabled);  
//               $(this).prop('checked', selectedRows.has(leadId));  
//             }
//           });
//       } 
//       if (selectedRows.size > 0) {
//         table.draw();
//       }
//       },
//       stateSave: true,
//     });
//     $(tableRef.current).on('click', '.print-lead-btn', function () {
//       const leadId = $(this).data('id');
//       const leadassigned = table.row($(this).closest('tr')).data(); 
//       printLead(leadassigned);  // Call the printLead function with the leadassigned data
//     });
//     $(tableRef.current).on('click', '.edit-lead-btn', function () {
//       const leadId = $(this).data('id');
//       navigate(`/editleadassigned/${leadId}`);
//     });
//     $(tableRef.current).on('change', '.row-checkbox', function () {
//       const leadId = $(this).data('leadId');
//       handleCheckboxChange(leadId);
//     });
//   return () => {
//     if ($.fn.DataTable.isDataTable(tableRef.current)) {
//       $(tableRef.current).DataTable().destroy();
//     }
//   };
// }, [UserId, pageNumber, pageSize]);

useEffect(() => {
  const token = AuthService.getToken(); 
  const table = $(tableRef.current).DataTable({
      processing: true,
      serverSide: true,
      ajax: {
          url: `${API_URL}/LeadAssigned/GetAllLeadAssignedByUserId/${UserId}/${pageNumber}/${pageSize}`,
          type: 'POST',
          datatype: 'json',
          headers: {
              "Authorization": `Bearer ${token}`, // Add the token here
          },
          dataSrc: function (json) {
              console.log("API Response:", json);
              return json.value.data.$values.map(row => {
                return {
                    ...row,
                    leadCode: row.lead ? row.lead.leadCode : '',  
                    firstName: row.lead ? row.lead.firstName : '', 
                    contactNumber: row.lead ? row.lead.contactNumber : '', 
                    source: row.lead ? row.lead.source : '', 
                    leadStatusName: row.leadStatus ? row.leadStatus.leadStatusName : '',
                    property: row.lead ? row.lead.property : '',  
                    name: row.user && row.user.firstName && row.user.lastName ? 
                    `${row.user.firstName} ${row.user.lastName}` : '',
                };
            }) || [];
          },
          error: function (xhr, error, code) {
              console.error('API Request failed:', error, xhr);
              toastService.showError("Error", "Failed to fetch leads data");
          },
      },
      columnDefs: [
          { targets: [0], visible: true, searchable: false },
      ],
      columns: [
        {
          data: "leadId",
          name: "Select",
          render: function (data, type, row) {
           const isChecked = selectedRows.has(row.leadId);
            const isDisabled = row.isLeadAssigned;
            return `<input type="checkbox" class="row-checkbox" data-row="${row.leadId}" ${isDisabled ? 'disabled' : ''} ${isChecked ? 'checked' : ''} /> `;
          },
          orderable: false, 
          searchable: false, 
        },
          {
              data: "conversationComment",
              name: "Conversation Comment",
              autoWidth: true
          },
          {
              data: "leadCode",
              name: "Lead Code",
              autoWidth: true
          },
          {
              data: "firstName",
              name: "First Name",
              autoWidth: true
          },
          {
              data: "contactNumber",
              name: "Mobile",
              autoWidth: true
          },
          {
              data: "source",
              name: "Source",
              autoWidth: true
          },
          {
              data: "leadStatusName",
              name: "Lead Status",
              render: function (data) {
                  // Apply appropriate classes based on status
                  return `<span class="${getStatusClass(data)}">${data}</span>`;
              },
              autoWidth: true,
          },
         
          {
              data: "property",
              name: "Property",
              autoWidth: true
          },
          {
              data: "leadAssignedDate",
              name: "Assigned Date",
              render: function (data) {
                  return new Date(data).toLocaleDateString();
              },
              autoWidth: true,
          },
          {
              data: "name",
              name: "Employee Name",
              autoWidth: true
          },
          {
              data: "followUpDate",
              name: "Follow Up Date",
              render: function (data) {
                  return new Date(data).toLocaleString('en-US', {
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      hour12: true,
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                  });
              },
              autoWidth: true,
          },
          {
              data: "nextUpdate",
              name: "Next Update",
              render: function (data) {
                  return new Date(data).toLocaleString('en-US', {
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      hour12: true,
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                  });
              },
              autoWidth: true,
          },
          {
              data: null,
              render: function (data, type, row) {
                  return `
                      <button class="btn btn-outline-dark btn-sm edit-lead-btn" data-id="${row.id}">
                          <i class="pi pi-pencil" style="font-size: 1.5rem;"></i>
                      </button>
                      ${(userRole === Role.SUPERADMIN.roleId || userRole === Role.ADMIN.roleId || userRole === Role.MANAGER.roleId)
                          ? `<button class="btn btn-outline-dark btn-sm" onClick="printLead(${row.id})">
                                <i class="fas fa-print" style="font-size: 1.5rem;"></i>
                              </button>`
                          : ''}
                  `;
              },
              orderable: false,
              searchable: false,
          },
      ],
      drawCallback: function (settings) {
        const currentPage = Math.floor(settings._iDisplayStart / settings._iDisplayLength) + 1;
        if (currentPage !== pageNumber) {
          setPageNumber(currentPage);  
          localStorage.setItem('currentPageNumber', currentPage);
          $(tableRef.current).find('.row-checkbox').each(function () {
            const leadId = $(this).data('row'); 
            const rowData = settings.aoData.find(row => row._aData.leadId === leadId);
            if (rowData) {
              const isDisabled = rowData._aData.isLeadAssigned;
              $(this).prop('disabled', isDisabled);  
              $(this).prop('checked', selectedRows.has(leadId));  
            }
          });
      } 
      if (selectedRows.size > 0) {
        table.draw();
      }
      },
      stateSave: true,
    });
    $(tableRef.current).on('click', '.print-lead-btn', function () {
      const leadId = $(this).data('id');
      const leadassigned = table.row($(this).closest('tr')).data(); 
      printLead(leadassigned);  // Call the printLead function with the leadassigned data
    });
    $(tableRef.current).on('click', '.edit-lead-btn', function () {
      const leadId = $(this).data('id');
      navigate(`/editleadassigned/${leadId}`);
    });
    $(tableRef.current).on('change', '.row-checkbox', function () {
      const leadId = $(this).data('leadId');
      handleCheckboxChange(leadId);
    });
  return () => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }
  };
}, [UserId, pageNumber, pageSize]);


  return (
    <div>
      <Toast ref={toast} />
      <h1>Assigned Leads</h1>

      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (

      <div className="form-group row">
              <div className="col-lg-6">
                <select
                  name="userId"
                  id="userId"
                  className={`form-control ${isEmployeeSelectedError ? 'error' : (selectedValue ? 'valid' : '')}`}
                  value={selectedValue}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  <option value="" disabled>Select an Employee</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.firstName}
                    </option>
                  ))}
                </select>
                {isEmployeeSelectedError && (
              <div className="text-danger mt-2">
                Please select an employee
              </div>
            )}
              </div>

          
                <div className="col-lg-3">
                    <button
                      className="btn btn-save text-white"
                      onClick={() => ReAssignLead()}
                      >
                     <MdRepeat /> Re-Assign Leads
                    </button>                
                </div>
        <div>

{/* 
        <div className="col-lg-3">
                   <h6>Filter List By Lead Status:</h6>              
                </div>
        <div className="form-group row">
          
        <div className="col-lg-6">
            <select className="form-control" value={selectedStatus} onChange={handleStatusChange}>
              <option value="">All</option>
              {leadStatus.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.leadStatusName}
                </option>
              ))}
            </select>
          </div>
          </div> */}


          <button
            className="btn btn-save offset-11 text-white"
            onClick={() => {
              navigate("/addleadsassigned");
            }}
          >
            <i className="pi pi-plus"></i> 
            Add
          </button>
        </div>
      </div>
      )} 

      <div className="p-2 m-2">
       
      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
       <div>
       <input
          type="checkbox"
          checked={selectAll}
          onChange={handleSelectAll}
        /> 
        <label>Select All</label>
       </div>
         )} 



      <table id="tableData" ref={tableRef} className="table display">
          <thead>
            <tr>
              {userRole == Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId ? (
                <th>Select</th>
              ) : null}
              <th>Conversation Comments</th>
              <th>Lead Code</th>
              <th>Lead Name</th>
              <th>Contact Number</th>
              <th>Source</th>
              <th>Lead Status</th>      
              <th>Property</th>
              <th>Lead Assigned Date</th>              
              <th>Employee</th>             
              <th>Follow Up</th>
              <th>Next FollowUp</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody> </tbody>
        </table>
      </div>
    </div>
  );
}

export default LeadsAssigned;