import React, { useEffect, useRef, useState } from 'react'
import {useNavigate, useParams } from 'react-router-dom';
import ToastService from '../../../Admin/services/ToastService';
import ClientService from '../../services/ClientService';
import { Toast } from 'primereact/toast';


function EditClient() {

  const toast = useRef(null);
  const { id } = useParams();  
  const navigate = useNavigate();
  const toastService = new ToastService(toast); 

  const [form, setForm] = useState({
      agencyName: "",
      email: "",
      phoneNumber: "",
      streetAddress: "",  
      city: "",
      state: "",
      country:"",
      postalCode:"",
      gstNumber:"",
      agencyLogo:null,
      license:"",
      registrationNumber:"",
      id: id,
  });

  const [formError, setFormError] = useState({}); 

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const data = await ClientService.getClientById(id);
        setForm(data);
      } catch (error) {
        console.error('Error fetching Clients data:', error);
      } 
    };

    fetchClients();
  }, [id]);
  
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForm(prevForm => ({ ...prevForm, [name]: value }));
  };
  

  const validateForm = () => {

    const errors = {};
    if (!form.agencyName.trim()) errors.agencyName = "Please enter the Client name.";
    setFormError(errors);

    if (Object.keys(errors).length > 0) {
      toastService.showWarning('Validation Error', 'Please fill out all required fields');
    }

    return Object.keys(errors).length === 0;
  };

  const onClientSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    updateClient();
  };

  
  const updateClient = async () => {
    const formDataToSend = new FormData();
    Object.keys(form).forEach(key => {
          formDataToSend.append(key, form[key]);
        }
      );
    try {
        debugger
      await ClientService.updateClient(id, formDataToSend); 
      toastService.showSuccess('Success', 'Client updated successfully');
      setTimeout(() => {
      navigate(-1);
      }, 1000); 
    } catch (error) {
      console.error("Error updating Client:", error);
      toastService.showError('Error', 'Failed to update Client');
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file); 
      reader.onload = () => {
        const agencyLogo = reader.result.split(',')[1]; 
        setForm({ ...form, agencyLogo });
        setFormError((prevErrors) => ({ ...prevErrors, agencyLogo: '' })); 
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    }
  };

  return (
    <div>
         <Toast ref={toast} />
        <h2>Edit Client</h2>

        <form onSubmit={onClientSubmit}>
        <div className="form-group row">
        <label className="col-lg-2" htmlFor="agencyName">
                        Name
                </label>
                <div className="col-lg-4">
                    <input 
                        type="text" 
                        name="agencyName" 
                        id="agencyName" 
                        className={`form-control ${formError.agencyName ? 'is-invalid' : ''}`}
                        placeholder="Agency Name" 
                        onChange={changeHandler} 
                        value={form.agencyName} 
                        />
                    {formError.clientName && <div className="invalid-feedback">{formError.clientName}</div>}   
                    <p></p> 
                </div>

                <label className="col-lg-2" htmlFor="email">
                        Email
                </label>
                <div className="col-lg-4">
                    <input 
                        type="text" 
                        name="email" 
                        id="email" 
                        className={`form-control ${formError.email ? 'is-invalid' : ''}`}
                        placeholder="Email" 
                        onChange={changeHandler} 
                        value={form.email} 
                        />

                </div>

            </div>

      <div className="form-group row">
      <label className="col-lg-2" htmlFor="phoneNumber">
              Phone Number
          </label>
          <div className="col-lg-4">
              <input type="text" name="phoneNumber" id="phoneNumber" 
              className={`form-control ${formError.phoneNumber ? 'is-invalid' : ''}`}
              placeholder="Phone Number" onChange={changeHandler}
              value={form.phoneNumber} 
              />
            <p></p> 
          </div>

      <label className="col-lg-2" htmlFor="streetAddress">
                Street Address
          </label>
          <div className="col-lg-4">
              <input 
              type="text" 
              name="streetAddress" 
              id="streetAddress"
              className={`form-control ${formError.streetAddress ? 'is-invalid' : ''}`}
              placeholder="State" onChange={changeHandler}
              value={form.streetAddress} 
              maxLength="13"
              />
                 
              <p></p>
          </div>
      </div>

        <div className="form-group row">

        <label className="col-lg-2" htmlFor="city">
              City
          </label>
          <div className="col-lg-4">
              <input type="text" name="city" id="city" 
              className={`form-control ${formError.city ? 'is-invalid' : ''}`}
              placeholder="Country" onChange={changeHandler}
              value={form.city} 
              />
            <p></p> 
          </div>

          <label className="col-lg-2" htmlFor="state">
                   State
          </label>
          <div className="col-lg-4">
              <input 
                type="text" 
                name="state" 
                id="state" 
                className={`form-control ${formError.state ? 'is-invalid' : ''}`}
                placeholder="Postal Code" 
                onChange={changeHandler} 
                value={form.state} 
                />
                <p></p> 
          </div>
          </div>                   
          <div className="form-group row">

<label className="col-lg-2" htmlFor="country">
   Country  
  </label>
  <div className="col-lg-4">
      <input type="text" name="country" id="country" 
      className={`form-control ${formError.country ? 'is-invalid' : ''}`}
      placeholder="country" onChange={changeHandler}
      value={form.country} 
      />
    <p></p> 
  </div>

  <label className="col-lg-2" htmlFor="postalCode">
  PostalCode
  </label>
  <div className="col-lg-4">
      <input 
        type="Number" 
        name="postalCode" 
        id="postalCode" 
        className={`form-control ${formError.postalCode ? 'is-invalid' : ''}`}
        placeholder="Postal Code" 
        onChange={changeHandler} 
        value={form.postalCode} 
        />
        <p></p> 
  </div>
  </div>   
  <div className="form-group row">

<label className="col-lg-2" htmlFor="gstNumber">
Gst Number  
  </label>
  <div className="col-lg-4">
      <input type="text" name="gstNumber" id="gstNumber" 
      className={`form-control ${formError.gstNumber ? 'is-invalid' : ''}`}
      placeholder="Gst Number" onChange={changeHandler}
      value={form.gstNumber} 
      />
    <p></p> 
  </div>

  <label className="col-lg-2" htmlFor="agencyLogo">
 Agency Logo
  </label>
  <div className="col-lg-4">
      <input 
        type="file" 
        name="agencyLogo" 
        id="agencyLogo" 
        className={`form-control ${formError.agencyLogo ? 'is-invalid' : ''}`}
        placeholder="Agency Logo" 
        onChange={onFileChange} 
        />
        <p></p> 
  </div>
  </div>   
  <div className="form-group row">

<label className="col-lg-2" htmlFor="license">
License  
  </label>
  <div className="col-lg-4">
      <input type="text" name="license" id="license" 
      className={`form-control ${formError.license ? 'is-invalid' : ''}`}
      placeholder="License" onChange={changeHandler}
      value={form.license} 
      />
    <p></p> 
  </div>

  <label className="col-lg-2" htmlFor="registrationNumber">
  Registration Number
  </label>
  <div className="col-lg-4">
      <input 
        type="text" 
        name="registrationNumber" 
        id="registrationNumber" 
        className={`form-control ${formError.registrationNumber ? 'is-invalid' : ''}`}
        placeholder="Registration Number" 
        onChange={changeHandler} 
        value={form.registrationNumber} 
        />
        <p></p> 
  </div>
  </div>   
        <div className="col-md-12 text-center">
            <button type="submit" className="btn btn-save text-white btn-center">Update</button>
        </div>
        </form>

        </div>
  )
}

export default EditClient